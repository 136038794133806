import * as React from "react";
import { throttle, debounce } from "lodash";

const calcTop = (ref) => {
  const target = ref.current;
  const rect = target.getBoundingClientRect();
  const yOffset = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + yOffset;
};

const calcRect = (ref) => {
  const target = ref.current;
  const rect = target.getBoundingClientRect();
  return rect;
};

const calcPositionRatio = (sectionTops, scrollY) => {
  let index = sectionTops.length - 2;
  let ratio = 1;
  for (let i = 1, l = sectionTops.length; i < l; i++) {
    const top = sectionTops[i];
    if (scrollY <= top) {
      index = i - 1;
      const upperTop = sectionTops[index];
      const range = top - upperTop;
      const scrollInRange = scrollY - upperTop;
      ratio = (scrollInRange === 0) ? 0 : scrollInRange / range;
      break;
    }
  }
  return {
    index: index,
    ratio: ratio
  };
};

const ScrollIndicator = ({ parentRef, sectionRefs, navRefs, isMobileMenu, isMenuOpened }) => {
  const [scrollY, setScrollY] = React.useState(null);
  const [layoutMemory, setLayoutMemory] = React.useState(null);
  const [styles, setStyles] = React.useState({});

  const bodyW = React.useRef(0);
  const bodyH = React.useRef(0);

  const calcSectionTops = () => {
    const tops = sectionRefs.current.map((ref) => {
      return calcTop(ref);
    });
    const last = tops.length - 1;
    tops[last] = Math.min(tops[last], document.documentElement.scrollHeight - window.innerHeight);
    return tops;
  };

  const calcNavRects = () => {
    const rects = navRefs.current.map((ref) => {
      return calcRect(ref);
    });
    return rects;
  };

  const updateLayoutMemory = () => {
    if (!sectionRefs.current || !navRefs.current) {
      return;
    }
    const width = document.body.clientWidth;
    const height = document.body.clientHeight;
    if (bodyW.current !== width || bodyH.current !== height) {
      bodyW.current = width;
      bodyH.current = height;
      const navRects = calcNavRects();
      const sectionTops = calcSectionTops();
      setLayoutMemory({
        navRects: navRects,
        sectionTops: sectionTops
      });
    }
  };

  const scrollHandler = () => {
    setScrollY(window.pageYOffset || document.documentElement.scrollTop);
    updateLayoutMemory();
  };

  const resizeHandler = () => {
    updateLayoutMemory();
  };

  React.useEffect(() => {
    if (scrollY === null || layoutMemory === null || !parentRef.current) {
      return;
    }
    if (isMobileMenu && !isMenuOpened) {
      return;
    }
    const navRects = layoutMemory.navRects;
    const sectionTops = layoutMemory.sectionTops;
    const {index, ratio} = calcPositionRatio(sectionTops, scrollY);
    const origin = navRects[index];
    const dist = navRects[index + 1];
    const parentRect = parentRef.current.getBoundingClientRect();
    if (isMobileMenu) {
      const originY = origin.top;
      const originH = origin.height;
      const distY = dist.top;
      const targetY = originY + ((distY - originY) * ratio) - parentRect.top;
      setStyles({
        display: 'block',
        left: 0,
        top: targetY,
        bottom: 'auto',
        width: 6,
        height: originH
      });
    } else {
      const originX = origin.left;
      const originW = origin.width;
      const distX = dist.left;
      const distW = dist.width;
      const targetX = originX + ((distX - originX) * ratio) - parentRect.left;
      const targetW = (originW * (1 - ratio)) + distW * ratio;
      setStyles({
        display: 'block',
        left: targetX,
        top: 'auto',
        bottom: 0,
        width: targetW,
        height: 3
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileMenu, isMenuOpened, scrollY, layoutMemory]);

  React.useEffect(()=> {
    const onResize = debounce(resizeHandler, 500);
    window.addEventListener('resize', onResize);
    const onScroll = throttle(scrollHandler, 60);
    window.addEventListener('scroll', onScroll);
    scrollHandler();
    resizeHandler();
    return (() => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onScroll);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <p id="scrollIndicator" style={styles}></p>
  );
};

export default ScrollIndicator;

import * as React from "react";

const StoryCatch = ({ bgImage, children }) => {
  const bg = bgImage.images.fallback.src;
  return (
    <div className="outerBlack">
      <div className="storyCatchOuter">
        <div className="clippingBG">
          <div className="fixedBackground" style={{backgroundImage: `url(${bg})`}}></div>
          <div className="storyCatchInner">
            <p>
              <strong>
                {children}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoryCatch;

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SectionGallery = React.forwardRef((_, ref) => {
  const data = useStaticQuery(
    graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "images/gallery"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
            id
          }
        }
        totalCount
      }
    }
    `
  );
  const images = React.useRef([]);
  for (let i = 0; i < data.allFile.totalCount; i++) {
    images.current[i] = React.createRef();
  }
  React.useEffect(() => {
    images.current.forEach((image) => {
      gsap.to(image.current, {
        duration: 0,
        width: '0%'
      });
      gsap.to(image.current, {
        duration: 1,
        width: '100%',
        ease: Power4.easeOut,
        scrollTrigger: {
          trigger: image.current,
          start: 'top+=50px bottom'
        }
      });
    });
  }, []);
  return (
    <section>
      <div id="sectionGallery" className="sectionContainer" ref={ref}>
        <h2 className="sectionTitle">ギャラリー</h2>
        <div className="outerWhite">
          <div className="galleryOuter">
            <ul>
              {data.allFile.edges.map(({node}, i) => (
                <li key={node.id}>
                  <div className="imageContainer" ref={images.current[i]}>
                    <GatsbyImage
                      image={node.childImageSharp.gatsbyImageData}
                      layout="constrained"
                      alt=""
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
});

export default SectionGallery;

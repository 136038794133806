import * as React from "react";
import { debounce } from "lodash";
import Scroller from "../lib/scroller";
import ScrollIndicator from "./scroll-indicator";

const Header = ({ sectionRefs }) => {
  const [opened, setOpened] = React.useState(false);
  const [isMobileMenu, setIsMobileMenu] = React.useState(false);
  const menuLabelRef = React.useRef(null);
  const headerInnerRef = React.useRef(null);
  const navRefs = React.useRef([]);
  for (let i = 0; i < 6; i++) {
    navRefs.current[i] = React.createRef();
  }

  const resizeHandler = () => {
    setIsMobileMenu(window.getComputedStyle(menuLabelRef.current).getPropertyValue('display') === 'block');
  };

  const changeHandler = (event) => {
    setOpened(event.target.checked);
  };

  const clickHandler = (event) => {
    event.preventDefault();
    if (opened) {
      setOpened(false);
    }
    const delay = (isMobileMenu) ? 0.4 : 0;
    const href = event.currentTarget.href;
    const index = href.indexOf('#');
    const hash = href.slice(index);
    Scroller.scrollTo(hash, delay);
  };

  React.useEffect(() => {
    const onResize = debounce(resizeHandler, 500);
    window.addEventListener('resize', onResize);
    resizeHandler();
    return (() => {
      window.removeEventListener('resize', onResize);
    });
  }, []);

  const refs = navRefs.current;
  return (
    <header>
      <input type="checkbox" id="menuState" checked={opened} onChange={changeHandler} />
      <label id="menuLabel" htmlFor="menuState" aria-hidden="true" ref={menuLabelRef}>
        <span id="menuLineTop"></span>
        <span id="menuLineMid"></span>
        <span id="menuLineBtm"></span>
      </label>
      <div id="headerOuter">
        <div id="headerInner" ref={headerInnerRef}>
          <nav>
            <div id="navOuter">
              <ul id="globalNav">
                <li ref={refs[0]}><a onClick={clickHandler} href="/#">TOP</a></li>
                <li ref={refs[1]}><a onClick={clickHandler} href="/#sectionTrailer">予告編</a></li>
                <li ref={refs[2]}><a onClick={clickHandler} href="/#sectionStory">ストーリー</a></li>
                <li ref={refs[3]}><a onClick={clickHandler} href="/#sectionDvd">DVD</a></li>
                <li ref={refs[4]}><a onClick={clickHandler} href="/#sectionGallery">ギャラリー</a></li>
                <li ref={refs[5]}><a onClick={clickHandler} href="/#sectionStaff">スタッフ</a></li>
              </ul>
            </div>
          </nav>
          <ScrollIndicator
            parentRef={headerInnerRef}
            sectionRefs={sectionRefs}
            navRefs={navRefs}
            isMobileMenu={isMobileMenu}
            isMenuOpened={opened}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;

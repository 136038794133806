import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import StoryCatch from "./story-catch";

const SectionStory = React.forwardRef((_, ref) => {
  const data = useStaticQuery(
    graphql`
    {
      image01: file(relativePath: {eq: "images/story_01.jpg"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 3200)
        }
      }
      image02: file(relativePath: {eq: "images/story_02.jpg"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 3200)
        }
      }
    }
    `
  );
  return (
    <section>
      <div id="sectionStory" className="sectionContainer" ref={ref}>
        <h2 className="sectionTitle">ストーリー</h2>
        <StoryCatch bgImage={getImage(data.image01)}>
          水、土、風、マングローブ、<br className="onlyMobile" />たくさんの生きものたち、そして人間…<br />
          すべてがひとつになって織りなされる<br className="onlyMobile" />西表島の自然は、<br />
          数え切れない「いのち」と<br className="onlyMobile" />ともにめぐり続ける
        </StoryCatch>
        <div className="outerWhite">
          <div className="storyWritingOuter">
            <div className="storyWritingInner">
              <h3 className="longHead">「奄美大島、徳之島、沖縄島北部及び西表島」が、<br className="onlyPC" />2021年7月、世界自然遺産に登録されました。</h3>
              <p>
                西表島はIUCNのレッドリストに載る絶滅危惧種の生息地であり、島の成り立ちを反映した独自の生物進化を背景にして、「生物多様性」の重要な地域であることが評価されたとのことです。
              </p>
              <p>
              私たちはちょうど20年前の2001年、西表島で5月から8月まで約100日間泊まり込みで撮影を行いました。当時は、田んぼで水牛が農夫とともに活躍していました。<br />これからも、水とマングローブと生き物たちの「いのちのめぐる島」であり続けて欲しいと願います。<br />
              （プロデューサー：小松原 時夫）
              </p>
            </div>
            <div className="storyWritingInner">
              <h3>解説</h3>
              <p>
                あたたかい南の海と風の中に、ぽっかりと浮かぶ西表島……。この西表島では『マングローブ』が、川と海が出会うところに深々とした森をつくっています。
              </p>
              <p>
                マングローブとは、海の水でも成長することができる、ちょっと変わった樹木のこと。海の水と川の水をともに受け入れることによって、マングローブは西表島のいろんな自然につながり、そして、たくさんの生き物たちの住処となっています。<br />
                水は、すべての自然、すべての生き物を結びつけます。食べるものも食べられるものも、生きるものも死ぬものも、西表島に暮らすすべての生き物が、マングローブを流れる水の中で巡り合い、ひとつに結ばれています。そして、私たち人間も、この水のめぐりの中で生きる生き物の一員なのです。
              </p>
              <p>
                もちろんこれは、西表島に限ったことではありません。どの国のどの地方でも、そこに暮らす生き物は、そこを流れる水となんらかの関わりを持って生きています。だけど、ここ西表島では、まだ豊富に残されているために、水と生き物たちの関わりをじかに見て、じかに感じることができるのです。<br />
                この作品は、マングローブの森の奥で、コツコツと泥を積みつづける不思議な生き物『オキナワアナジャコ』を手がかりに西表島で撮影された、水とマングローブと生き物たちの物語です。
              </p>
            </div>
          </div>
        </div>
        <StoryCatch bgImage={getImage(data.image02)}>
          青い海と暖かい風につつまれた<br className="onlyMobile" />南の島、西表島。<br />
          島をおおう亜熱帯の自然のなかで、<br className="onlyMobile" />マングローブとそこに生きる生きもの、<br />
          特にオキナワアナジャコを<br className="onlyMobile" />観察することによって、<br className="onlyMobile" />土と水のふるまいを見つめ、<br />
          水田を媒介とした人間との<br className="onlyMobile" />つながりまでを描き出す。
        </StoryCatch>
        <div className="outerWhite">
          <div className="storyWritingOuter">
            <div className="storyWritingInner">
              <h3>演出にあたって</h3>
              <p>
                沖縄本島からさらに南西へ450km。黒潮の流れのただ中に、緑と雲のかたまりのような島が浮いていた。それが西表島だった。撮影の下見のために西表島を訪れた時から、私はこの島にすっかりとりつかれてしまった。
              </p>
              <p>
                なぜなら、西表島には未だ手つかずの自然が豊富に残されているから。<br />
                なぜなら、マングローブの森の奥で水と土をあやつって、自分が生きていく世界を自分でつくりだしている、ある農夫と、オキナワアナジャコという小さな生き物に出会ったから。<br />
                私は、西表島の自然をそこなうことなくお米をつくることができる島の人々の生活技術を、本当にうらやましく思った。そして、毎日泥を運びつづけるオキナワアナジャコに、生きるたくましさを感じた。
              </p>
              <p>
                この、島の人々の暮らしとオキナワアナジャコを手がかりに、マングローブと生き物たちの関わり、そしてさらには、西表島をめぐる大きな大きな水や命の流れを描いてみたいと思った。そして、可能な限り「観察」によって、自然のありのままの姿をとらえてみたいと思った。そうすればきっと、西表島の息吹が伝わるはずだ……。そんなことを考えながら、マングローブでの撮影が3ヶ月続いた。<br />
                映像の仕事について日の浅い私が、どこまで西表島を描くことができたのかはわからない。だけどこの間にも、マングローブは枝葉をいっぱい茂らせ、オキナワアナジャコは泥を掘り、農夫は水田で働いているはずだ。西表島がある限り、みんな毎日しっかり生きているにちがいない。<br />
                （監督：西森一夫）
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default SectionStory;

import * as React from "react";
import Seo from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import SectionTop from "../components/section-top";
import SectionTrailer from "../components/section-trailer";
import SectionStory from "../components/section-story";
import SectionDvd from "../components/section-dvd";
import SectionGallery from "../components/section-gallery";
import SectionStaff from "../components/sectionStaff";

const IndexPage = () => {
  const sectionRefs = React.useRef([]);
  for (let i = 0; i < 6; i++) {
    sectionRefs.current[i] = React.createRef();
  }
  const refs = sectionRefs.current;
  return (
    <div id="wrapper">
      <Seo />
      <Header sectionRefs={sectionRefs} />
      <main>
        <article>
          <SectionTop ref={refs[0]} />
          <SectionTrailer ref={refs[1]} />
          <SectionStory ref={refs[2]} />
          <SectionDvd ref={refs[3]} />
          <SectionGallery ref={refs[4]} />
          <SectionStaff ref={refs[5]} />
        </article>
      </main>
      <Footer />
    </div>
  );
}

export default IndexPage;

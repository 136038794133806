import * as React from "react";

const SectionTrailer = React.forwardRef((_, ref) => {
  return (
    <section>
      <div id="sectionTrailer" className="sectionContainer" ref={ref}>
        <h2 className="sectionTitle">予告編</h2>
        <div className="outerBlack">
          <div className="youtubeContainer">
            <iframe width="1280" height="960" src="https://www.youtube.com/embed/BS_bSJ6CMfQ?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
});

export default SectionTrailer

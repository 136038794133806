import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const SectionTop = React.forwardRef((_, ref) => {
  const [styles, setStyles] = React.useState({});
  React.useEffect(() => {
    const divHeight = parseFloat(window.getComputedStyle(ref.current).getPropertyValue('height'));
    const innerHeight = window.innerHeight;
    if (innerHeight < divHeight) {
      setStyles({
        height: innerHeight
      });
    }
  }, [ref]);
  return (
    <section>
      <div id="sectionTop" ref={ref} style={styles}>
        <div id="topHeroOuter">
          <StaticImage
            src="../images/hero.png"
            layout="fullWidth"
            alt=""
          />
          <h1 id="titleLogo">
            <StaticImage
              src="../images/logo.png"
              layout="constrained"
              placeholder="none"
              alt="西表島 世界自然遺産登録記念『いのちめぐる島 イリオモテ』"
            />
          </h1>
          <p id="photoBy">写真提供：横塚眞己人</p>
        </div>
      </div>
    </section>
  );
});

export default SectionTop;

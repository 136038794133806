import * as React from "react";

const SectionStaff = React.forwardRef((_, ref) => {
  return (
    <section>
      <div id="sectionStaff" className="sectionContainer" ref={ref}>
        <h2 className="sectionTitle">スタッフ</h2>
        <div className="outerGray">
          <div className="staffOuter">
            <dl>
              <dt>ナレーション：</dt><dd>田中好子<br /><br /></dd>
              <dt>製作：</dt><dd>小松原時夫<br />住田 望</dd>
              <dt>監督：</dt><dd>西森一夫</dd>
              <dt>監修：</dt><dd>富田京一</dd>
              <dt>撮影：</dt><dd>寺門勝利</dd>
              <dt>音楽：</dt><dd>原公一郎</dd>
              <dt>編集：</dt><dd>村本 勝</dd>
              <dt>整音：</dt><dd>滝澤 修</dd>
              <dt>VE：</dt><dd>落合智成</dd>
              <dt>CG制作：</dt><dd>渡部 洋</dd>
              <dt>EED：</dt><dd>久保田尚<br />落合正夫</dd>
              <dt>リライト：</dt><dd>杉本信昭</dd>
              <dt>撮影コーディネイト：</dt><dd>横塚眞己人</dd>
              <dt>制作・著作：</dt><dd>株式会社モンタージュ</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
});

export default SectionStaff;

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const SectionDvd = React.forwardRef((_, ref) => {
  return (
    <section>
      <div id="sectionDvd" className="sectionContainer" ref={ref}>
        <h2 className="sectionTitle">DVD</h2>
        <div className="outerGray">
          <div className="dvdOuter">
            <div className="dvdColumnR">
            <StaticImage
              src="../images/dvd.png"
              layout="fullWidth"
              alt=""
            />
            </div>
            <div className="dvdColumnL">
              <p>
                Amazonにてご購入頂けます。下記の「Amazonで見る」ボタンをクリックして頂くことで、Amazonのページに移動できます。
              </p>
              <p>
                <a href="https://www.amazon.co.jp/dp/B09BBJNYTH/ref=cm_sw_em_r_mt_dp_8F93FSN8BJ0H857XE900" target="_blank" rel="noreferrer" className="amazonBtn"><span>Amazonで見る</span></a>
              </p>
              <StaticImage
                src="../images/amazon_qr.png"
                layout="constrained"
                className="amazonQR"
                placeholder="none"
                alt=""
              />
              <ul>
                <li>定価：3,300円（税込み）</li>
                <li>メディア形式：DVD-Video</li>
                <li>収録時間：47分</li>
                <li>言語：日本語 (Dolby Digital 2.0 Stereo)</li>
              </ul>
              <StaticImage
                src="../images/dvd_spec.png"
                layout="constrained"
                placeholder="none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default SectionDvd;

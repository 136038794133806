import * as React from "react";

const Footer = () => {
  return (
    <footer>
      <div id="footerOuter">
        <p id="copyright"><small>&copy; MONTAGE Inc.</small></p>
      </div>
    </footer>
  );
}

export default Footer;
